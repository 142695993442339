export const DEEPLINK_ME_PAGE = 'mxtakatak://mxplay.com/home?tab=me'

export function mxCreateDeepLink (url, type) {
  return fetch(`https://seo.mxtakatak.com/api/v1/app/get-share-url?id=${encodeURIComponent(url)}&pageType=${type}`).then(res => res.json()).then(res => {
    return res.shareUrl
  })
}

export function mxCreateShareUrl (url, type) {
  url = `mxplay.com/page/link/inner?url=${encodeURIComponent(url)}&type=${type}`
  url = `intent://${url}#Intent;scheme=mxtakatak;package=com.next.innovation.takatak;S.browser_fallback_url=market://details?id=com.next.innovation.takatak;end`
  return `https://mxshorts.mxplay.com/redirect?url=${encodeURIComponent(url)}`
}

export function mxcommon () {
  let headerInfoStr = window.clientBridge && window.clientBridge.getCommonInfo && window.clientBridge.getCommonInfo()
  let headerInfo = headerInfoStr && JSON.parse(headerInfoStr)
  return headerInfo || {}
}

export function mxuser () {
  let userInfoStr = window.clientBridge && window.clientBridge.getUserInfo && window.clientBridge.getUserInfo()
  let userInfo = userInfoStr && JSON.parse(userInfoStr)
  return userInfo || {}
}

export function mxGetUserById (uid) {
  let hasBridgeHandler = !!(window.clientBridge && window.clientBridge.getUserInfo)

  let url = `v1/publisher?id=${uid || ''}`
  if (!hasBridgeHandler) {
    return mxget(url)
  } else {
    let user = mxuser()
    if (!uid || user.id === uid) {
      return Promise.resolve(user)
    } else {
      return mxget(url)
    }
  }
}

export function mxHideTitleBar () {
  window.clientBridge && window.clientBridge.setTitleBarEnable && window.clientBridge.setTitleBarEnable(0)
}

const appVersion = mxcommon()['x-app-version']
export const SUPPORT_HIDE_TITLE_BAR = !!(window.clientBridge && window.clientBridge.setTitleBarEnable && (appVersion > 11603 || appVersion < 11600))

export function mxBackup () {
  window.clientBridge && window.clientBridge.backPressed && window.clientBridge.backPressed()
}
export const SUPPORT_BACKUP = !!(window.clientBridge && window.clientBridge.backPressed)
export const SUPPORT_LINKPHONE = !!(window.clientBridge && window.clientBridge.linkPhone)

export function toast (obj) {
  if (!window.clientBridge || !window.clientBridge.toast) {
    throw new Error('no client bridge object')
  }
  window.clientBridge.toast(JSON.stringify({ ...obj }))
}

export function mxLinkPhone () {
  return new Promise((resolve, reject) => {
    if (!window.clientBridge || !window.clientBridge.linkPhone) {
      reject(new Error('no client bridge object'))
      return
    }

    window.onMXClientLinkPhoneDone = function (res) {
      console.log(res)
      if (res.errCode !== 0) {
        reject(new Error('link failed'))
        return
      }

      resolve(true)
    }

    window.clientBridge.linkPhone('onMXClientLinkPhoneDone')
  })
}

export function linkPhone () {
  return new Promise((resolve, reject) => {
    if (!window.clientBridge || !window.clientBridge.linkPhone) {
      reject(new Error('no client bridge object'))
      return
    }

    window.onMXClientLinkPhoneDone = function (res) {
      if (res.errCode !== 0) {
        resolve(false)
        return
      }

      resolve(true)
    }

    window.clientBridge.linkPhone('onMXClientLinkPhoneDone')
  })
}

export function mxtrack (name, params) {
  window.clientBridge.track(name, JSON.stringify(params || {}))
}

export function withdrawRewardVerify (obj) {
  return new Promise((resolve, reject) => {
    if (!window.clientBridge || !window.clientBridge.withdrawRewardVerify) {
      reject(new Error('no client bridge object'))
      return
    }

    window.onwithdrawRewardVerifyDone = function (res) {
      console.log(res)
      if (res.errCode !== 0) {
        reject(new Error('failed'))
        return
      }

      resolve(res.result)
    }

    window.clientBridge.withdrawRewardVerify(JSON.stringify(obj), 'onwithdrawRewardVerifyDone')
  })
}

export function unLinkWithdrawAccount (obj) {
  return new Promise((resolve, reject) => {
    if (!window.clientBridge || !window.clientBridge.unLinkWithdrawAccount) {
      reject(new Error('no client bridge object'))
      return
    }

    window.onunLinkWithdrawAccountDone = function (res) {
      console.log(res)
      if (res.errCode !== 0) {
        reject(new Error('unlink failed'))
        return
      }

      resolve(res.result)
    }

    window.clientBridge.unLinkWithdrawAccount(JSON.stringify(obj), 'onunLinkWithdrawAccountDone')
  })
}

export function copyToClipboard (obj) {
  if (!window.clientBridge || !window.clientBridge.copyToClipboard) {
    throw new Error('no client bridge object')
  }
  window.clientBridge.copyToClipboard(JSON.stringify({ ...obj }))
}

export function linkWithdrawAccount (obj) {
  return new Promise((resolve, reject) => {
    if (!window.clientBridge || !window.clientBridge.linkWithdrawAccount) {
      reject(new Error('no client bridge object'))
      return
    }

    window.onlinkWithdrawAccountDone = function (res) {
      console.log(res)
      if (res.errCode !== 0) {
        reject(new Error('link failed'))
        return
      }

      resolve(res.result)
    }

    window.clientBridge.linkWithdrawAccount(JSON.stringify(obj), 'onlinkWithdrawAccountDone')
  })
}

export function mxRecharge (conf) {
  console.log(conf)
  return new Promise((resolve, reject) => {
    if (!window.clientBridge || !window.clientBridge.recharge) {
      reject(new Error('no client bridge object'))
      return
    }

    window.onMXRechargeDone = function (res) {
      console.log(res)
      if (res.errCode !== 0) {
        reject(new Error('recharge failed'))
        return
      }
      resolve(res.result.paymentData.verifyResult.nameValuePairs || {})
    }

    window.clientBridge.recharge(JSON.stringify(conf), 'onMXRechargeDone')
  })
}
export function liveSettingsChanged (liveConfig) {
  console.log(liveConfig)
  if (!window.clientBridge || !window.clientBridge.liveSettingsChanged) {
    throw new Error('no client bridge object')
  }
  window.clientBridge.liveSettingsChanged(liveConfig)
}

export function mxlogin ({ title }) {
  return new Promise((resolve, reject) => {
    if (!window.clientBridge || !window.clientBridge.login) {
      reject(new Error('no client bridge object'))
      return
    }

    window.onMXClientLoginDone = function (res) {
      if (res.errCode !== 0) {
        reject(new Error('login failed'))
        return
      }

      resolve()
    }

    window.clientBridge.login(JSON.stringify({ title }), 'onMXClientLoginDone')
  })
}

export const SUPPORT_SHARE = !!(window.clientBridge && window.clientBridge.share)

export function mxshare ({ type, content }) {
  return new Promise((resolve, reject) => {
    if (!window.clientBridge || !window.clientBridge.share) {
      reject(new Error('no client bridge object'))
      return
    }

    window.onMXClientShareDone = function (res) {
      if (res.errCode !== 0) {
        reject(new Error('share failed'))
        return
      }

      resolve()
    }

    window.clientBridge.share(JSON.stringify({ type, content }), 'onMXClientShareDone')
  })
}

export function mxdeeplink ({ link }) {
  return new Promise((resolve, reject) => {
    if (!window.clientBridge || !window.clientBridge.request) {
      reject(new Error('no client bridge object'))
      return
    }

    window.onMXClientOpenBrowserDone = function (res) {
      if (res.errCode !== 0) {
        reject(new Error('open link failed'))
        return
      }

      resolve()
    }

    window.clientBridge.openBrowser(JSON.stringify({ url: link }), 'onMXClientOpenBrowserDone')
  })
}

let requestCount = 0
function getRequestCount () {
  requestCount++
  if (requestCount > 1000000) {
    requestCount = 0
  }
  return requestCount
}

export function mxrequest (payload) {
  return new Promise((resolve, reject) => {
    if (!window.clientBridge || !window.clientBridge.request) {
      reject(new Error('no client bridge object'))
      return
    }

    let callbackName = `onMXClientRequestDone${getRequestCount()}`
    window[callbackName] = function (res) {
      if (res.errCode !== 0 || res.result.statusCode !== 200) {
        reject(new Error(`request failed: ${res.errCode || res.result.statusCode} ${payload.url} ${JSON.stringify(payload.body)}`))
        return
      }
      delete window[callbackName]

      resolve(res)
    }

    window.clientBridge.request(JSON.stringify(payload), callbackName)
  })
}

export function mxget (url) {
  let payload = {
    'method': 'GET',
    'url': url
  }

  return mxrequest(payload).then(res => res.result.data)
}

export function mxpost (url, body) {
  let payload = {
    'method': 'POST',
    'url': url,
    'body': body
  }

  return mxrequest(payload).then(res => res.result.data)
}
