<template>
  <div class="home">
    <div style="display: flex;justify-content: center;">
      <div class="title t-16 t-medium" :style="{width: screenWidth > 500 ? '420px' : '300px'}">
        <h2 id="title1" style="margin-top:20px;" @mouseenter="home1 = true" @mouseleave="home1 = false">
          <div style="display: flex;">
            <span>The best way to enjoy your movies</span>
            <span v-show="screenWidth > 800" style="margin-left: 10px;" class="titleH3Svg">
              <svg v-show="home1" width="22px" height="22px" viewBox="0 0 24 24" fill="currentColor" focusable="false" @click="getUrl('title1')">
                <path d="M0 0h24v24H0z" fill="none"></path>
                <path d="M3.9 12c0-1.71 1.39-3.1 3.1-3.1h4V7H7c-2.76 0-5 2.24-5 5s2.24 5 5 5h4v-1.9H7c-1.71 0-3.1-1.39-3.1-3.1zM8 13h8v-2H8v2zm9-6h-4v1.9h4c1.71 0 3.1 1.39 3.1 3.1s-1.39 3.1-3.1 3.1h-4V17h4c2.76 0 5-2.24 5-5s-2.24-5-5-5z"></path>
              </svg>
            </span>
          </div>
        </h2>
      </div>
    </div>
    <div class="imgBox">
      <img class="homeImg" src="../assets/homeImg.png" alt="">
    </div>
    <div>
      <div class="text">
        <p>
          <span>Key Features</span>
        </p>
        <ul style="list-style-type: square;">
          <li class="ml15pt">
            <p><span><strong>Hardware Acceleration</strong></span><span> - Hardware acceleration can be applied to more videos with the help of new HW+ decoder.</span></p>
          </li>
          <li class="ml15pt">
            <p><span><strong>Multi-core Decoding -</strong></span><span> MX Player is the first Android video player which supports multi-core decoding. Test result proved that dual-core device’s performance is better up to 70% than that of single-core devices.</span></p>
          </li>
          <li class="ml15pt">
            <p><span><strong>Pinch to Zoom, Zoom and Pan</strong></span><span>- Easily zoom in and out by pinching and swiping across the screen. Zoom and Pan is also available by option.</span></p>
          </li>
          <li class="ml15pt">
            <p><span><strong>Subtitle gestures</strong></span><span> - Scroll forward/backward to move to next/previous text, Up/down to move text up and down, Zoom in/out to change text size.</span></p>
          </li>
          <li class="ml15pt">
            <p><span><strong>Kids Lock </strong></span><span>- Keep your kids entertained without having to worry about them making calls or touching other apps. (plugin required)</span></p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'home',
  data () {
    return {
      home1: false
    }
  },
  methods: {
    getUrl (id) {
      let a = window.location.href.split('#')[0]
      var url = a + '#' + id
      var el = document.createElement('input')
      el.setAttribute('value', url)
      document.body.appendChild(el)
      el.select()
      document.execCommand('Copy')
      document.body.removeChild(el)
    }
  },
  computed: {
    screenWidth () {
      return this.$parent.screenWidth
    },
    origin () {
      return this.$parent.origin
    }
  },
  mounted () {
    this.$nextTick(() => {
      if (this.$route.hash !== '') {
        let id = this.$route.hash.split('#')[1]
        let el = document.getElementById(id)
        el.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'nearest'
        })
      }
    })
  }
}
</script>

<style scoped>
ul li p{
  text-align: left;
  text-indent: 0;
}
.ml30pt{
  margin-left: 30pt;
}
.ml15pt{
  margin-left: 15pt;
}
.home{
  width: 100%;
  height: 100%;
  overflow: scroll;
  font-family: 'Muli';
}
.title{
  width: 440px;
  height: 100px;
  color: rgba(11,83,148,1);
  font-family: 'Muli';
  text-align: center;
}
.titleH3Svg{
  margin-top: 3px;
  vertical-align: middle;
  cursor: pointer;
  position: relative;
}
.imgBox{
  max-width: 850px;
  max-height: 580px;
  margin: 10px 0 0 10%;
}
.homeImg{
  width: 100%;
  height: 100%;
}
.text{
  width: 80%;
  margin: 30px 0 30px 10%;
}
.text ul li{
  font-family: 'Muli';
}
.text ul li p{
  margin: 8px 0;
}
</style>
