import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.PROJECT_NAME,
  routes: [
    // {
    //   path: '/',
    //   redirect: '/home'
    // },
    {
      path: '/',
      name: 'index',
      meta: {
        title: 'Home'
      },
      component: Home
    },
    {
      path: '/home',
      name: 'home',
      meta: {
        title: 'Home'
      },
      component: Home
    },
    {
      path: '/features',
      name: 'features',
      meta: {
        title: 'Features'
      },
      component: () => import(/* webpackChunkName: "features" */ './views/Features.vue')
    },
    {
      path: '/download',
      name: 'download',
      meta: {
        title: 'Download'
      },
      component: () => import(/* webpackChunkName: "download" */ './views/Download.vue')
    },
    {
      path: '/buy',
      name: 'buy',
      meta: {
        title: 'Buy'
      },
      component: () => import(/* webpackChunkName: "buy" */ './views/Buy.vue')
    },
    {
      path: '/api',
      name: 'intent definition',
      meta: {
        title: 'API - Intent definition'
      },
      component: () => import(/* webpackChunkName: "api-api" */ './views/API-IntentDefinition.vue')
    },
    {
      path: '/ffmpeg',
      name: 'custom codec',
      meta: {
        title: 'Custom codec'
      },
      component: () => import(/* webpackChunkName: "ffmpeg" */ './views/CustomCodec.vue')
    },
    {
      path: '/faq',
      name: 'faq',
      meta: {
        title: 'FAQ'
      },
      component: () => import(/* webpackChunkName: "faq" */ './views/FAQ.vue')
    },
    {
      path: '/report-an-issue',
      name: 'report an issue',
      meta: {
        title: 'Report an Issue'
      },
      component: () => import(/* webpackChunkName: "report-an-issue" */ './views/ReportAnIssue.vue')
    },
    {
      path: '/translation',
      name: 'translation',
      meta: {
        title: 'Translation'
      },
      component: () => import(/* webpackChunkName: "translation" */ './views/Translation/index.vue')
    },
    {
      path: '/translation/test-build',
      name: 'beta build',
      meta: {
        title: 'Beta build',
        parent: 'Translation'
      },
      component: () => import(/* webpackChunkName: "beta-build" */ './views/Translation/BetaBuild.vue')
    },
    {
      path: '/about',
      name: 'about',
      meta: {
        title: 'About'
      },
      component: () => import(/* webpackChunkName: "about" */ './views/About/index.vue')
    },
    {
      path: '/about/open-source-license',
      name: 'open source license',
      meta: {
        title: 'Open source license',
        parent: 'About'
      },
      component: () => import(/* webpackChunkName: "open-source-license" */ './views/About/OpenSourceLicense.vue')
    },
    {
      path: '/about/privacy-policy',
      name: 'mx player privacy policy',
      meta: {
        title: 'MX Player Privacy Policy',
        parent: 'About'
      },
      component: () => import(/* webpackChunkName: "MXPlayer-Privacy-Policy" */ './views/About/MXPlayerPrivacy.vue')
    },
    {
      path: '/about/mx-pro-privacy-policy',
      name: 'mx player pro privacy policy',
      meta: {
        title: 'MX Player PRO Privacy Policy',
        parent: 'About'
      },
      component: () => import(/* webpackChunkName: "MXPlayer-PRO-Privacy-Policy" */ './views/About/MXPlayerPROPrivacyPolicy.vue')
    },
    {
      path: '/about/term-of-service',
      name: 'terms of service',
      meta: {
        title: 'Terms of Service',
        parent: 'About'
      },
      component: () => import(/* webpackChunkName: "Terms-of-Service" */ './views/About/TermsOfService.vue')
    },
    {
      path: '/business-cooperation',
      name: 'business cooperation',
      meta: {
        title: 'Business Cooperation'
      },
      component: () => import(/* webpackChunkName: "Business-Cooperation" */ './views/BusinessCooperation.vue')
    },
    {
      path: '/about/policy-on-age-restricted-content',
      name: 'policy on age restricted content',
      meta: {
        title: 'Policy on Age-Restricted Content',
        parent: 'About',
        hidden: true
      },
      component: () => import(/* webpackChunkName: "Business-Cooperation" */ './views/About/policyOnAge-RC.vue')
    },
    {
      path: '/about/policy-on-age-restricted-content-non-in',
      name: 'policy-on-age-restricted-content-non-in',
      meta: {
        title: 'Policy on Age-Restricted Content(non-IN)',
        parent: 'About',
        hidden: true
      },
      component: () => import(/* webpackChunkName: "Business-Cooperation" */ './views/About/policyOnAge-RC(non-IN).vue')
    },
    {
      path: '/about/eu-privacy-policy',
      name: 'eu privacy policy',
      meta: {
        title: 'MX Player Privacy Policy(EU)',
        parent: 'About',
        hidden: true
      },
      component: () => import(/* webpackChunkName: "Business-Cooperation" */ './views/About/MXPlayerPrivacy(EU).vue')
    },
    {
      path: '/about/mx-player-privacy-policy-non-ott',
      name: 'non ott privacy policy',
      meta: {
        title: 'MX Player Privacy Policy(non OTT)',
        parent: 'About',
        hidden: true
      },
      component: () => import(/* webpackChunkName: "Business-Cooperation" */ './views/About/MXPlayerPrivacy(non OTT).vue')
    },
    {
      path: '/about/mx-pro-eu-privacy-policy',
      name: 'eu pro privacy policy',
      meta: {
        title: 'MX Player PRO Privacy Policy(EU)',
        parent: 'About',
        hidden: true
      },
      component: () => import(/* webpackChunkName: "Business-Cooperation" */ './views/About/MXPlayerPROPrivacyPolicy(EU).vue')
    },
    {
      path: '/about/terms-of-service-non-ott',
      name: 'terms of service non ott',
      meta: {
        title: 'Terms of Service(non OTT)',
        parent: 'About',
        hidden: true
      },
      component: () => import(/* webpackChunkName: "Business-Cooperation" */ './views/About/TermsOfService(non OTT).vue')
    }
  ]
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title
  next()
})

export default router
