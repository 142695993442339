import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueGtag from 'vue-gtag'
import { sync } from 'vuex-router-sync'

Vue.config.productionTip = false

Vue.use(VueGtag, {
  config: { id: process.env.VUE_APP_PTOJECT_GA_ID }
}, router)

sync(store, router)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
