<template>
  <div ref="page" id="app">
    <div class="title-bar t-500 t-20" @click="backup" v-if="showTitleBar">
      <svg class="backup" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24" height="24" viewBox="0 0 24 24">
          <defs>
              <path id="4if71xs0aa" d="M3.83 7l4.882-4.882c.391-.391.393-1.02-.002-1.413C8.318.315 7.676.324 7.29.71L.71 7.29c-.392.392-.386 1.034 0 1.42l6.58 6.58c.392.392 1.023.397 1.415.005.39-.39.389-1.022-.002-1.413L3.83 9h11.174c.55 0 .996-.444.996-1 0-.552-.448-1-.996-1H3.83z"/>
          </defs>
          <g fill="none" fill-rule="evenodd">
              <g>
                  <g>
                      <g>
                          <g transform="translate(-16 -40) translate(0 24) translate(16 16) translate(4 4)">
                              <use fill="#FFF" xlink:href="#4if71xs0aa"/>
                          </g>
                      </g>
                  </g>
              </g>
          </g>
      </svg>
      <span>{{title}}</span>
    </div>
    <div class="main">
      <transition name="transform">
        <div class="nav" v-show="!isFAQ && (screenWidth > 1280 || clickedMenu)" :style="{ 'float' : screenWidth < 600 ? 'left' : 'none' }">
          <div v-show="clickedMenu" class="closeBtn">
            <svg class="menuBtn" viewBox="0 0 24 24" stroke="currentColor" jsname="B1n9ub" focusable="false" @click="clickedMenu = false">
              <g transform="translate(12, 12)">
                <path class="hlJH0" d="M-6 -6 L6 6" fill="none" stroke-width="2"></path>
                <path class="HBu6N" d="M-6 6 L6 -6" fill="none" stroke-width="2"></path>
              </g>
            </svg>
          </div>
          <div v-show="!clickedMenu" class="logo-box">
            <img src="./assets/mxplayer.png" alt="iconLogo" class="img-logo" @click="jump('/home')">
          </div>
          <div class="nav-route-box">
            <ul class="ul-content t-16">
              <li v-for="(item,index) in routeList" :key="index">
                <div :class="[selectedItem === item.label ? 'selected' : '', 'li-content', (item.label === 'Translation' || item.label === 'About') ? 'li-content2' : '']">
                  <div v-if="item.label === 'Translation' || item.label === 'About'" class="mr-4">
                    <svg :class="['svgIcon', item.clicked ? 'overturn' : '']" viewBox="0 0 24 24" stroke="currentColor" focusable="false" @click="toggleShow(item)">
                      <g transform="translate(9.7,12) rotate(45)">
                        <path d="M-4.2 0 L4.2 0" stroke-width="2"></path>
                      </g>
                      <g transform="translate(14.3,12) rotate(-45)">
                        <path d="M-4.2 0 L4.2 0" stroke-width="2"></path>
                      </g>
                    </svg>
                  </div>
                  <span :class="selectedItem === item.label ? 't-bold' : ''" @click="selectItem(item)">{{ item.label }}</span>
                </div>
                <div v-if="item.children" style="overflow: hidden;">
                  <div :class="item.clicked ? 'childrenShow' : 'childrenHidden'">
                    <ul class="ul-content t-16">
                      <li v-for="(child, i) in item.children" :key="i">
                        <div :class="[selectedItem === child.label ? 'selected' : '', 'li-content', 'li-content3']">
                          <span @click="selectItem(child)">{{ child.label }}</span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </transition>
      <div class="mask" v-show="clickedMenu"></div>
      <div class="view" :style="{ width: ( screenWidth > 1280 || (clickedMenu && screenWidth < 1280 && screenWidth > 600)) ? 'calc(100% - 250px)' : '100%', 'margin-left' : (clickedMenu && screenWidth < 600) ? '-250px' : '0' }">
        <transition name="transform">
          <div class="topHeader" v-show="!isFAQ && (!clickedMenu && screenWidth < 1280)">
            <div class="menuBtnBox" v-if="this.source !== 'mx'">
              <svg class="menuBtn" viewBox="0 0 24 24" stroke="currentColor" jsname="B1n9ub" focusable="false" @click="topClick">
                <g transform="translate(12,12)">
                  <path class="hlJH0" d="M-9 -5 L9 -5" fill="none" stroke-width="2"></path>
                  <path class="HBu6N" d="M-9 0 L9 0" fill="none" stroke-width="2"></path>
                  <path class="cLAGQe" d="M-9 5 L9 5" fill="none" stroke-width="2"></path>
                </g>
              </svg>
            </div>
            <div class="topLogo">
              <img src="./assets/mxplayer.png" alt="iconLogo" class="img-logo" @click="jump('/home')">
            </div>
          </div>
        </transition>
        <router-view :style="{'padding-top': screenWidth < 1280 ? '56px' : '0px', 'box-sizing': 'border-box'}" />
      </div>
    </div>
    <!-- <div class="caution">
      <svg width="24" height="24" viewBox="0 0 24 24" focusable="false" style="margin: 2px;">
        <path d="M11 17h2v-6h-2v6zm1-15C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zM11 9h2V7h-2v2z"></path>
      </svg>
    </div> -->
  </div>
</template>

<script>
import { mxBackup, SUPPORT_HIDE_TITLE_BAR, SUPPORT_BACKUP } from 'mxclient'
import { mapState } from 'vuex'

export default {
  data () {
    return {
      disableTitleBarFromClient: window.location.search.indexOf('titleBar=0') >= 0,
      routeList: [
        { label: 'Home', path: '/home' },
        { label: 'Features', path: '/features' },
        { label: 'Download', path: '/download' },
        { label: 'Buy', path: '/buy' },
        { label: 'API - Intent definition', path: '/api' },
        { label: 'Custom codec', path: '/ffmpeg' },
        { label: 'FAQ', path: '/faq' },
        { label: 'Report an Issue', path: '/report-an-issue' },
        { label: 'Translation', path: '/translation', clicked: false, children: [ { label: 'Beta build', path: '/translation/test-build' } ] },
        { label: 'About',
          path: '/about',
          clicked: false,
          children: [
            { label: 'Open source license', path: '/about/open-source-license' },
            { label: 'MX Player Privacy Policy', path: '/about/privacy-policy' },
            { label: 'MX Player PRO Privacy Policy', path: '/about/mx-pro-privacy-policy' },
            { label: 'Terms of Service', path: '/about/term-of-service' }
          ]
        },
        { label: 'Business Cooperation', path: '/business-cooperation' }
      ],
      screenWidth: 0,
      clickedMenu: false,
      origin: '',
      source: ''
      // selectedItem: 'Home'
    }
  },
  computed: {
    ...mapState(['route']),
    showTitleBar () {
      return SUPPORT_HIDE_TITLE_BAR && this.disableTitleBarFromClient
    },
    title () {
      return this.route.meta.title
    },
    selectedItem () {
      return this.$route.meta.hidden ? '' : this.$route.meta.title
    },
    isFAQ () {
      let flag = false
      if (this.$route.meta.title === 'FAQ') {
        flag = true
        window.location.href = 'https://support.mxplayer.in/support/home'
      }
      return flag
    }
  },
  methods: {
    backup () {
      if (this.$route.name === 'home') {
        for (let i = 0; i < 100; i++) {
          this.$router.go(-1)
        }
      }

      mxBackup()
    },
    toggleShow (item) {
      item.clicked = !item.clicked
    },
    selectItem (item) {
      this.clickedMenu = false
      this.jump(item.path)
    },
    jump (path) {
      // let p = path.toLowerCase()
      // if (p.indexOf('-') !== -1) {
      //   p = 'intent definition' // 使用api-intent definition路由会出问题
      // }
      // this.$router.push({ name: p })
      if (this.source === 'mx') return
      window.location.href = this.origin + path
    },
    topClick () {
      if (this.screenWidth < 600) {
        this.needMarginLeft = true
      }
      this.clickedMenu = true
    },
    expendMenu () {
      if (this.$route.meta.title === 'Translation' || this.$route.meta.title === 'About') {
        this.$route.meta.title === 'Translation' ? this.routeList[8].clicked = true : this.routeList[9].clicked = true
      }
      if (this.$route.meta.parent) {
        this.routeList.forEach(el => {
          if (el.label === this.$route.meta.parent) {
            el.clicked = true
          }
        })
      }
    }
  },
  created () {
    if (SUPPORT_BACKUP) {
      window.onAppBackClicked = () => {
        this.backup()
      }
    }
    this.source = window.location.href.indexOf('source=mx') !== -1 ? 'mx' : ''
  },
  mounted () {
    const that = this
    this.screenWidth = document.body.clientWidth
    window.onresize = () => {
      return (() => {
        window.screenWidth = document.body.clientWidth
        if (window.screenWidth > 1280) {
          this.clickedMenu = false
        } else {
          this.clickedMenu = false
        }
        that.screenWidth = window.screenWidth
      })()
    }
    this.origin = window.location.origin
    // this.$nextTick(() => {
    //   this.expendMenu()
    // })
    setTimeout(() => {
      this.expendMenu()
    }, 100)
  },
  destroyed () {
    if (SUPPORT_BACKUP) {
      window.onAppBackClicked = null
    }
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@200;300;400;500;800&display=swap');

@font-face {
  font-family: 'Muli';
  src: url("./assets/Muli/Muli-Regular.ttf") format("truetype"); /* chrome, firefox, opera, Safari, Android, iOS 4.2+ */
}

@font-face {
  font-family: "Muli-Bold";
  src: url("./assets/Muli/Muli-Bold.ttf") format("truetype"); /* chrome, firefox, opera, Safari, Android, iOS 4.2+ */
}

@font-face {
  font-family: "Muli-ExtraBold";
  src: url("./assets/Muli/Muli-ExtraBold.ttf") format("truetype"); /* chrome, firefox, opera, Safari, Android, iOS 4.2+ */
}

@font-face {
  font-family: "Muli-BlackItalic";
  src: url("./assets/Muli/Muli-BlackItalic.ttf") format("truetype"); /* chrome, firefox, opera, Safari, Android, iOS 4.2+ */
}

@font-face {
  font-family: "Muli-Italic";
  src: url("./assets/Muli/Muli-Italic.ttf") format("truetype"); /* chrome, firefox, opera, Safari, Android, iOS 4.2+ */
}

@font-face {
  font-family: "Muli-BoldItalic";
  src: url("./assets/Muli/Muli-BoldItalic.ttf") format("truetype"); /* chrome, firefox, opera, Safari, Android, iOS 4.2+ */
}

@font-face {
  font-family: "Muli-SemiBoldItalic";
  src: url("./assets/Muli/Muli-SemiBoldItalic.ttf") format("truetype"); /* chrome, firefox, opera, Safari, Android, iOS 4.2+ */
}

@font-face {
  font-family: "Muli-Medium";
  src: url("./assets/Muli/Muli-Medium.ttf") format("truetype"); /* chrome, firefox, opera, Safari, Android, iOS 4.2+ */
}

@font-face {
  font-family: "Muli-Black";
  src: url("./assets/Muli/Muli-Black.ttf") format("truetype"); /* chrome, firefox, opera, Safari, Android, iOS 4.2+ */
}

body {
  margin: 0;
  padding: 0;
  font-family: Roboto, sans-serif;
}

ul {
  margin: 0;
  padding: 0;
}
/* li {
  list-style: none;
} */

.t-11 {
  font-size: 11px;
}
.t-12 {
  font-size: 12px;
}
.t-14 {
  font-size: 14px;
}
.t-16 {
  font-size: 16px;
}
.t-18 {
  font-size: 18px;
}
.t-20 {
  font-size: 20px;
}
.t-22 {
  font-size: 22px;
}

.t-bold {
  font-weight: 800;
}
.t-medium {
  font-weight: 500;
}

#app > .title-bar {
  display: flex;
  align-items: center;
  height: 56px;
  padding-left: 16px;
}
#app > .title-bar > .backup {
  margin-right: 16px;
}

</style>

<style scoped>
  li {
    list-style: none;
  }
  .mr-4{
    margin-right: 4px;
  }
  .ml-20{
    margin-left: 20px;
  }
  .main{
    width: 100vw;
    height: 100vh;
    background: rgba(255,255,255,1);
    display: flex;
    overflow: hidden;
  }
  .topHeader{
    width: 100vw;
    height: 56px;
    background: rgba(11,83,148,1);
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    z-index: 999;
  }
  .menuBtnBox{
    width: 42px;
    height: 56px;
    padding: 14px;
    padding-right: 0;
    box-sizing: border-box;
  }
  .menuBtn{
    width: 24px;
    height: 24px;
    color: rgba(249,249,249,1);
    cursor: pointer;
  }
  .closeBtn{
    width: 56px;
    height: 56px;
    padding: 14px;
    box-sizing: border-box;
  }
  .topLogo{
    width: 300px;
    height: 56px;
    margin-top: 8px;
    padding-left: 14px;
  }
  .mask{
    width: calc(100% - 250px);
    height: 100%;
    position: fixed;
    left: 250px;
    top: 0;
    background: black;
    opacity: 0.5;
    z-index: 999;
  }
  .nav{
    width: 250px;
    height: 100%;
    background: rgba(11,83,148,1);
    z-index: 900;
  }
  .view{
    /* width: calc(100% - 250px); */
    height: 100%;
  }
  .logo-box{
    width: 100%;
    height: 90px;
    box-sizing: border-box;
    padding: 48px 32px 0 48px;
  }
  .nav-route-box{
    width: 100%;
    height: calc(100% - 110px);
  }
  .img-logo{
    width: 138px;
    height: 36px;
    cursor: pointer;
  }
  .ul-content{
    width: 100%;
    height: 100%;
    color: white;
    box-sizing: border-box;
  }
  .ul-content li{
    min-height: 20px;
    line-height: 20px;
    vertical-align: middle;
    margin-top: 16px;
  }
  .ul-content li span {
    cursor: pointer;
    vertical-align: middle;
    display: inline-block;
  }
  .li-content{
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 0 10px 0 50px;
  }
  .li-content2{
    padding-left: 24px;
    display: flex;
  }
  .li-content3{
    padding: 0 20px 0 70px;
  }
  .svgIcon{
    float: left;
    width: 20px;
    height: 20px;
    cursor: pointer;
    transition: all .3s linear 0s;
  }
  .overturn{
    transform: rotate(180deg);
    transition: all .3s linear 0s;
  }
  .childrenShow{
    margin-top: 0px;
    transition: all .3s linear 0s;
  }
  .childrenHidden{
    margin-top: -210px;
    transition: all .3s linear 0s;
  }
  .selected{
    position: relative;
  }
  .selected::after{
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 8px;
    height: 20px;
    background: rgba(249,249,249,1);
  }
  .caution{
    width: 28px;
    height: 28px;
    position: fixed;
    bottom: 20px;
    left: 275px;
    cursor: pointer;
    border-radius: 10px;
    background: aliceblue;
  }
  .caution:hover{
    background: rgba(218,220,224,0.749);
  }
  .transform-enter-active, .transform-leave-active {
    transition: 'max-height' 1s;
    /* transition: display 1s; */
    /* transition: opacity 1s; */
  }
  .transform-enter, .transform-leave-to /* .fade-leave-active below version 2.1.8 */ {
    max-height: 0px;
    /* display: none; */
    /* opacity: 0; */
  }
</style>
